// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"cbcd7ee04dc65b80285ac7c47e5a57489f09c96a"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/qantashotelsui";import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        if (hint?.event.target.dataTestid) {
          breadcrumb.message = hint?.event.target.dataTestid;
        } else if (hint?.event.target.ariaLabel) {
          breadcrumb.message = hint?.event.target.ariaLabel;
        } else {
          breadcrumb.message = hint?.event.target.class;
        }
      }
      return breadcrumb;
    },
    ignoreErrors: ["Failed to execute 'importScripts'"],
    denyUrls: [/\/widgets\/universal-nav\//, /\/universal-navigation-widget\//],
  });
}
